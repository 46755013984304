import React, {Component} from 'react'
import styles from "../../css/items.module.css"
import Title from "../Title"
import PortalFile from "./PortalFile";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PortalMessage from "./PortalMessage";
import PortalAppointment from "./PortalAppointment";
import PortalUser from "./PortalUser";

var axios = require("axios");

class PortalList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            S3Asset: [],
            myS3Asset: [],
            keyfolder: '',
            myMessages: [],
            myAppointments: [],
            user: ''
        }

        // alert(props.keyfolder);
        this.getMyFiles = this.getMyFiles.bind(this);
        this.getMyMessages = this.getMyMessages.bind(this);
        this.getMyAppointments = this.getMyAppointments.bind(this);
        this.getMyInfo = this.getMyInfo.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        this.setState({
            keyfolder: this.props.keyfolder,
            user: this.props.user
        })
    }

    getMyInfo() {
        this.setState({
            user: this.props.user
        })
    }
    getMyAppointments() {
        const headers = {'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'};
        const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
            },
            mode: 'no-cors'
        };
        let params = this.props.keyfolder.split('-');

        console.log(this.props.keyfolder);
        const payload = {
                "action": "appointmentread",
                "email": params[0],
                "patientpin": params[1],
                "treatmentcenterid": params[2].replace('/','')
                // "prefix": "joe@terrahealthsolutions1.com-123456-1923/"
            };



        axios.post(apiUrl, JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                var respMess = response.data;

                respMess.forEach((node) => {
                    console.log(node.portal_message_content);
                });

                this.setState({
                    myAppointments: respMess
                });


            })
            .catch((err) => {
                console.log('error from API', err);
            });
    }

    getMyMessages() {
        // this.setState({
        //     loading: true
        // });
        // alert('keyfolder ' + this.state.keyfolder);
        // alert('keyfolder ' + this.props.keyfolder);
        const headers = {'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'};
        const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
            },
            mode: 'no-cors'
        };
        let params = this.props.keyfolder.split('-');

console.log(this.props.keyfolder);
        const payload = {
                "action": "messageread",
                "email": params[0],
                "patientpin": params[1],
                "treatmentcenterid": params[2].replace('/','')
                // "prefix": "joe@terrahealthsolutions1.com-123456-1923/"
            }
        ;
        axios.post(apiUrl, JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                var respMess = response.data;
                // resp = [
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1923/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     },
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1924/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     },
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1925/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     }
                // ];

                respMess.forEach((node) => {
                    console.log(node.portal_message_content);
                });

                this.setState({
                    myMessages: respMess
                });

                console.log(' my messages ', this.state.myMessages);

            })
            .catch((err) => {
                console.log('error from API', err);
            });
    }

    getMyFiles() {
        // this.setState({
        //     loading: true
        // });
        // alert('keyfolder ' + this.state.keyfolder);
        // alert('keyfolder ' + this.props.keyfolder);
        const headers = {'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'};
        const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
            },
            mode: 'no-cors'
        };
        const payload = {
                "action": "readfolders",
                "bucketname": "get-the-care",
                "prefix": this.props.keyfolder
                // "prefix": "joe@terrahealthsolutions1.com-123456-1923/"
            }
        ;
        axios.post(apiUrl, JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                var resp = response.data.Contents;
                // resp = [
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1923/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     },
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1924/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     },
                //     {
                //         ETag: "d41d8cd98f00b204e9800998ecf8427e",
                //         Key: "joe@terrahealthsolutions1.com-123456-1925/",
                //         LastModified: "2021-01-28T01:33:50.000Z"
                //     }
                // ];

                resp.map((node) => {
                    console.log(node.Key);
                });

                this.setState({
                    myS3Asset: resp
                });

            })
            .catch((err) => {
                console.log('error from API', err);
            });
    }

    // componentDidMount() {
    //     this.setState({
    //         S3Asset: ''
    //     })
    // }

    render() {

        var self = this;
        return (
            <section>
                <Title title="Patient" subtitle="Portal"/>
                {/*<button onClick={this.getMyFiles}> Get My Files </button>*/}
                {/*<div className={styles.center}>*/}
                {/*    {this.state.S3Asset && this.state.S3Asset.map(({ node }) => {*/}
                {/*        return <PortalFile key={node.id} s3file={node} />*/}
                {/*    })}*/}
                {/*</div>*/}
                <br/>

                <div>
                    <Tabs className={styles.tabs}>
                        <TabList className={styles.tabList}>
                            <Tab>
                                <p onLoad={this.getMyInfo}>My Info</p>
                            </Tab>
                            <Tab>
                                <p onClick={this.getMyMessages}>Messages</p>
                            </Tab>
                            <Tab>
                                <p onClick={this.getMyAppointments}>Appointments</p>
                            </Tab>
                            <Tab>
                                <p onClick={this.getMyFiles}>Documents</p>
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className={styles.panelcontent}>
                                <PortalUser user = {self.state.user}/>
                                {/*{<li> Name: {self.state.user.nickname}</li>}*/}
                                {/*{<li> UserId: {self.state.user.name}</li>}*/}
                                {/*{<li> Email: {self.state.user.email}</li>}*/}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.panelcontent}>
                                {(!self.state.myMessages || self.state.myMessages.length === 0 || self.state.myMessages === null)
                                    ? (<p>No messages.</p>) :
                                    (<div className={styles.center}>
                                        <ul>
                                        {self.state.myMessages && self.state.myMessages.map((messagedata) => {

                                            return <PortalMessage message={messagedata}/>
                                        })}
                                        </ul>
                                    </div>)
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.panelcontent}>
                                {(!self.state.myAppointments || self.state.myAppointments.length === 0 || self.state.myAppointments === null)
                                    ? (<p>No messages.</p>) :
                                    (<div className={styles.center}>
                                        <ul>
                                            {self.state.myAppointments && self.state.myAppointments.map((appdata) => {

                                                return <PortalAppointment message={appdata}/>
                                            })}
                                        </ul>
                                    </div>)
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.panelcontent}>
                                {(!self.state.myS3Asset || self.state.myS3Asset.length === 0 || self.state.myS3Asset === null || self.state.myS3Asset.errorType == 'TypeError')
                                    ? (<p>No files found.</p>) :
                                    (<div className={styles.center}>
                                        {/*<label>{this.state.myS3Asset}</label>*/}
                                        {self.state.myS3Asset && self.state.myS3Asset.map((node) => {
                                            return <PortalFile s3file={node}/>
                                        })}
                                    </div>)
                                }
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>

                {/*<label>My Documents</label>*/}
                {/*{(!self.state.myS3Asset || self.state.myS3Asset.length === 0 || self.state.myS3Asset === null || self.state.myS3Asset.errorType == 'TypeError')*/}
                {/*    ?(<p>No files found.</p>):*/}
                {/*    (<div className={styles.center}>*/}
                {/*        /!*<label>{this.state.myS3Asset}</label>*!/*/}
                {/*        {self.state.myS3Asset && self.state.myS3Asset.map(( node ) =>{*/}
                {/*    return <PortalFile s3file={node} />*/}
                {/*})}*/}
                {/*    </div>)*/}
                {/*}*/}
            </section>
        )
    }
}

export default PortalList
