import React from 'react'
import styles from "../../css/place.module.css"
import {ErrorBoundary} from 'react-error-boundary'
import { FaDochub, FaCloudDownloadAlt } from "react-icons/fa"


const PortalAppointment = ({ message }) => {
    console.log('portal message',message )
    const  Key = message.portal_message_content;

    const errorHandler = (error, componentStack) => {
        console.log(error)
        console.log(componentStack)
    }

    return (
        <ErrorBoundary onError={errorHandler}>
            <article>
                <div className={styles.footer}>
                    {/*<h3>{Key}</h3>*/}
                    {/*<FaCloudDownloadAlt />*/}
                    <li>{message.portal_message_content}</li>
                    <span>Appointment Date: {message.alert_date}</span>
                </div>
            </article>
        </ErrorBoundary>
    )
}

export default PortalAppointment
