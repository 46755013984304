import React from 'react'
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from 'gatsby'
import Places from '../components/Places/Places'
import SEO from "../components/SEO"
import Banner from "../components/Banner";
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile, getUserMetadata } from "../utils/auth"
import { Link } from "gatsby"
import Portal from "../components/Portal/Portal";

export const query = graphql`
query {
    defaultBcg: file(relativePath: {eq: "blogBcg.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 3160) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;




export default function portal({ data }) {
    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }
    const user = getProfile()
    var keyfolder = user.email + '-' + user.family_name + '-' + user.given_name + '/'

    if (user.email != undefined) {

        // alert(' this is user folder ' + keyfolder);
        // email + patient_id + treatment_center_id

        // var keyfolder = 'joe@terrahealthsolutions1.com-123456-1923/';
        //getUserMetadata()

        return (
            <Layout>
                <SEO title="Places" description="Search Rehabs."/>
                <StyledHero img={data.defaultBcg.childImageSharp.fluid}>
                    <Banner
                        title="Personal Portal"
                        info="Personal portal contains any files that are applicable to you"
                    >

                    </Banner>
                </StyledHero>
                <p>Logged in User: {user.name ? user.name : "Not logged in"}!</p>
                <a
                    href="#logout"
                    onClick={e => {
                        logout()
                        e.preventDefault()
                    }}
                >
                    Log Out
                </a>
                {user.name ? (
                    (<Portal keyfolder={keyfolder} user={user}/>)
                ) : ''
                }

            </Layout>
        )
    }
    else{
        login()
        return <p>Still loading ...</p>
    }
}
