import React from 'react'
import styles from "../../css/place.module.css"
import {ErrorBoundary} from 'react-error-boundary'
import { FaDochub, FaCloudDownloadAlt } from "react-icons/fa"
var axios = require("axios");


const PortalFile = ({ s3file }) => {
    const { Key} = s3file;

    const errorHandler = (error, componentStack) => {
        console.log(error)
        console.log(componentStack)
    }
    const downloadFile = (Key) =>{
        // alert( ' Thsi is the event ' + Key);
        const headers = { 'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM' };
        const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
            },
            mode: 'no-cors'
        };
        const payload = {

            "action": "readfile",
            "bucketname": "get-the-care",
            // "key": "joe@terrahealthsolutions1.com-123456-1923/3797261255_receipt_21.1.2021.pdf"
             "key": Key
            };

        axios.post(apiUrl,JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                // var resp = response.blob();

                // console.log(resp);
                // return resp.blob();
                // const writer = createWriteStream(outputLocationPath);

                var resp = response.data.body;
                // resp.pipe(writer)
                // const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
                const url = resp;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `3797261255_receipt_21.1.2021.pdf`);
                // link.setAttribute('ContentType', resp.ContentType);
                // link.setAttribute('ContentLength', resp.ContentLength);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
            })
            .catch((err) => {
                console.log('error from API', err);
            });
    }
    return (
        <ErrorBoundary onError={errorHandler}>
        <article>
            <div className={styles.footer}>
                {/*<h3>{Key}</h3>*/}
                <FaCloudDownloadAlt />
                <a id={Key} href="#"  onClick={() => downloadFile(Key)}>{Key}</a>
            </div>
        </article>
        </ErrorBoundary>
    )
}

export default PortalFile
