import React, {useState} from 'react'
import styles from "../../css/place.module.css"
import {ErrorBoundary} from 'react-error-boundary'
// import { FaDochub, FaCloudDownloadAlt } from "react-icons/fa"
import { updateUserMetadata } from "../../utils/auth"

const PortalUser = ({ user }) => {

    const errorHandler = (error, componentStack) => {
        console.log(error)
        console.log(componentStack)
    }

    const [treatmentCenterId,setTreatmentCenterId] = useState(user.given_name);
    const [patientPin,setPatientPin] = useState('');


    const updateUser = () =>{

        updateUserMetadata({treatmentCenterId}, {patientPin},{user});
    }
    return (
        <ErrorBoundary onError={errorHandler}>
            <article>
                <div className={styles.footer}>
                    {<li> Name: {user.nickname}</li>}
                    {<li> UserId: {user.name}</li>}
                    {<li> Email: {user.email}</li>}
                    <br/>
                    {
                        <p className={styles.tip}> Note: Update of Treatment Center Id and/or Patient Pin
                            will remove your previous records. </p>
                    }
                    <div className={styles.container}>


                    {<li> TreatmentCenter Id
                        <input type="text" value={user.given_name} onChange={event => setTreatmentCenterId(event.target.value)}/>
                    </li>}
                    {<li> Patient Pin:
                        <input value={user.family_name}  onChange={event => setPatientPin(event.target.value)}/>
                    </li>}
                    </div>
                    <div className={styles.footer}>
                        {
                            <button onClick={updateUser} className={styles.btnPrimary}> Update </button>
                        }
                    </div>

                </div>
            </article>
        </ErrorBoundary>
    )
}

export default PortalUser
