import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PortalList from "./PortalList";

// const getFiles = graphql`
// query {
//    S3Asset:  allS3Asset (limit: 10) {
//     edges {
//       node {
//         Key
//         id
//         url
//       }
//     }
//   }
// }
// `
const Portal = (props) => {
    // const { S3Asset } = useStaticQuery(getFiles)
    // alert(' Thsi is  ' + props.keyfolder);
    return <PortalList keyfolder={props.keyfolder} user={props.user}/>
}

export default Portal
